import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ImprintPage = () => (
  <Layout>
    <Seo title="Imprint" description="The legal stuff of the website." />
    <div className="max-w-screen-md mx-auto px-4 relative z-20 mt-20">
      <h2 className="text-4xl lg:text-5xl font-bold font-display text-center">Imprint</h2>
      <div className="prose-xl mt-12">
        <p>
          <span className="font-bold">Address</span><br/>
          Sustainable Digital Infrastructure Alliance e.V.<br/>
          Colonnaden 5<br/>
          20354 Hamburg<br/>
          Germany
        </p>

        <p>
          <span className="font-bold">Contact</span><br/>
          Phone: +49 40 22862448 <br/>
          E-mail: privacy@sdialliance.org<br/>
          website: www.sdialliance.org
        </p>

        <p>
          <span className="font-bold">Representation</span><br/>
          Chief Executive Officer: Max Schulze
        </p>

        <p>
          <span className="font-bold">Club register</span><br/>
          Local court Hamburg VR24110
        </p>

        <p>
          <span className="font-bold">Responsible for journalistic or editorial content:</span><br/>
          Max Schulze
        </p>

        <p>
          <span className="font-bold">DPA</span><br/>
          Max Schulze
        </p>

        <p>
          <span className="font-bold">Legal notice</span><br/>
          No guarantee can be given for the correctness, completeness and topicality of the information provided on this website. The website of the Sustainable Digital Infrastructure Alliance e.V. contains links and banners that refer to websites of other providers. If you follow these links, you will leave the own website of the Sustainable Digital Infrastructure Alliance e.V. The Sustainable Digital Infrastructure Alliance e.V. does not take responsibility for the correctness of information provided on third party websites, nor does it necessarily share the opinions expressed there.
          The Sustainable Digital Infrastructure Alliance e.V. reserves all intellectual property rights to the information on this website, the website design, the texts, all graphics, each selection or layout. This applies in particular to copyrights, whether or not they are specifically marked with a © note, and trademarks, whether or not they are specifically marked with the TM note.
        </p>
          
        <p>German law shall apply.</p>
      </div>
    </div>
  </Layout>
)

export default ImprintPage
